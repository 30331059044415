import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';

function FlashMessage({ type, message, test, scrollIntoView, extraClasses }) {
  const ref = useRef(null);

  useEffect(() => {
    if (scrollIntoView && typeof window !== 'undefined' && ref.current) {
      ref.current.scrollIntoView();
    }
  });

  if (!message) return null;

  return (
    <div ref={ref} className={extraClasses}>
      <Card
        extraClasses={`c-card--${type} u-margin-bottom-small u-padding-small`}
      >
        <p data-cy={test} className="u-margin-none">
          {message}
        </p>
      </Card>
    </div>
  );
}

FlashMessage.defaultProps = {
  type: 'info',
};

FlashMessage.propTypes = {
  extraClasses: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  scrollIntoView: PropTypes.bool,
};

export default FlashMessage;
