import {
  CREATE_VACANCY_ALERT,
  DELETE_ALL_VACANCY_ALERTS,
  DELETE_VACANCY_ALERT,
} from 'lib/mutations';
import { useMutation } from '@apollo/client';

export default function useVacancyAlert({ applicantId = '' } = {}) {
  const [createVacancyAlert, { error: createVacancyAlertError }] = useMutation(
    CREATE_VACANCY_ALERT,
    {
      refetchQueries: ['CURRENT_USER_QUERY'],
      awaitRefetchQueries: true,
    }
  );
  const [
    deleteVacancyAlert,
    {
      data: deleteVacancyAlertData,
      error: deleteVacancyAlertError,
      loading: deleteVacancyAlertLoading,
    },
  ] = useMutation(DELETE_VACANCY_ALERT);
  const [deleteAllVacancyAlerts, { error: deleteAllVacancyAlertsError }] =
    useMutation(DELETE_ALL_VACANCY_ALERTS, {
      variables: {
        applicantId,
      },
    });

  return {
    createVacancyAlert,
    createVacancyAlertError,
    deleteVacancyAlert,
    deleteVacancyAlertData,
    deleteVacancyAlertError,
    deleteVacancyAlertLoading,
    deleteAllVacancyAlerts,
    deleteAllVacancyAlertsError,
  };
}
