import { useEffect, useState } from 'react';
import {
  CREATE_CV,
  UPDATE_CV,
  UPLOAD_MEDIA_OBJECT,
  DELETE_ALL_CVS_APPLICANT,
} from 'lib/mutations';
import { useMutation } from '@apollo/client';

export default function useCv({ applicantId = '' } = {}) {
  const [createCv, { error: createCvError }] = useMutation(CREATE_CV);
  const [updateCv, { error: updateCvError }] = useMutation(UPDATE_CV);
  const [uploadMediaObject, { error: uploadCvError }] =
    useMutation(UPLOAD_MEDIA_OBJECT);
  const [deleteCv, { error: deleteCvError }] = useMutation(
    DELETE_ALL_CVS_APPLICANT,
    {
      variables: {
        applicantId,
      },
    }
  );
  const [saveCv, setSaveCv] = useState(() => createCv);

  useEffect(() => {
    // This function runs when the things we are watching change
    if (applicantId) return setSaveCv(() => updateCv);
  }, [applicantId]);

  return {
    saveCv,
    createCv,
    updateCv,
    uploadMediaObject,
    deleteCv,
    saveCvError: createCvError || updateCvError,
    uploadCvError,
    deleteCvError,
  };
}
