import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import useForm from './useForm';

export default function useFilterHeader({
  autoCompleteIndex,
  homepageFilter,
} = {}) {
  const router = useRouter();
  const { query: routerQuery, pathname } = router;
  const { inputs, handleChange } = useForm({
    autocomplete: routerQuery.query || '',
  });
  const [location, setLocation] = useState({
    place: routerQuery.place || '',
    lat: routerQuery.lat || '',
    lng: routerQuery.lng || '',
  });
  const [loading, setLoading] = useState();
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(useRef());

  const fetchAutocompleteSuggestions = async query => {
    setLoading(true);
    await fetch(
      `/api/elastic/search-autocomplete?query=${query}${
        autoCompleteIndex ? `&index=${autoCompleteIndex}` : ''
      }`
    )
      .then(async res => {
        const { results } = await res.json();
        if (!results) return setAutocompleteSuggestions([]);
        const sourceField =
          autoCompleteIndex === 'company_branch' ? 'name' : 'title';
        const suggestions = results.map(
          suggestion => suggestion._source[sourceField]
        );
        setAutocompleteSuggestions(suggestions);
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  const handleInputChange = e => {
    handleChange(e);
    const {
      target: { value },
    } = e;

    if (value?.length > 1) {
      setFetchTimeout(clearTimeout(fetchTimeout));

      setFetchTimeout(
        setTimeout(() => {
          fetchAutocompleteSuggestions(value);
        }, 350)
      );
    }

    if (value?.length === 0) {
      setAutocompleteSuggestions([]);
    }
  };

  const onSelect = value => {
    handleChange({ target: { value, name: 'autocomplete' } });
  };

  const clearInput = () => {
    handleInputChange({ target: { value: '', name: 'autocomplete' } });
  };

  // Function for retrieving the url parameters based on state
  const getUrlParameters = () => {
    const { place, lat, lng } = location;
    const urlParameter = {
      query: inputs.autocomplete,
      place,
      lat,
      lng,
    };
    // Only return variables which have a value
    return Object.keys(urlParameter)
      .filter(k => urlParameter[k] != null)
      .reduce((a, k) => ({ ...a, [k]: urlParameter[k] }), {});
  };

  const handleSubmit = () => {
    if (!inputs?.autocomplete && !location.place) return;
    // Do not submit if the value length is 1
    if (inputs?.autocomplete?.length === 1) return;
    let routeToPath = pathname;
    // This means we are on the homepage, redirect the user to the vacancies page
    if (homepageFilter) {
      routeToPath = '/vacatures';
    }
    // Update the url with the new parameters
    router.push({
      pathname: routeToPath,
      query: getUrlParameters(),
    });
  };

  return {
    inputs,
    handleChange: handleInputChange,
    loading,
    onSelect,
    clearInput,
    autocompleteSuggestions,
    location,
    setLocation,
    handleSubmit,
  };
}
