import Link from 'next/link';
import PropTypes from 'prop-types';

export const Property = ({ Icon, label, value, href, fullWidth, noIcon }) => (
  <div
    className={`c-properties__property ${
      fullWidth ? 'c-properties__property--full-width' : ''
    } ${noIcon ? 'c-properties__property--no-icon' : ''}`.trim()}
  >
    {!noIcon && (
      <div className="c-properties__property-icon-container" aria-hidden>
        <Icon className="c-properties__property-icon" />
      </div>
    )}
    <dt className="u-visually-hidden">{label}</dt>
    <dd className="c-properties__property-value">
      {href ? (
        <Link href={href}>
          <a>{value}</a>
        </Link>
      ) : (
        value
      )}
    </dd>
  </div>
);

const Properties = ({ properties, extraClasses }) => (
  <dl className={`c-properties ${extraClasses || ''}`.trim()}>
    {properties.map((property, index) => {
      if (property.value) {
        return (
          <Property
            key={`${property.label}-${property.value}-${index}`}
            Icon={property.Icon}
            label={property.label}
            value={property.value}
            fullWidth={property.fullWidth}
            noIcon={property.noIcon}
            href={property.href}
          />
        );
      }
      return null;
    })}
  </dl>
);

Property.defaultProps = {
  fullWidth: false,
};

Property.propTypes = {
  Icon: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  noIcon: PropTypes.bool,
  href: PropTypes.string,
};

Properties.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  extraClasses: PropTypes.string,
};

export default Properties;
