import ErrorPage from 'components/ErrorPage';
import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // Check if the error is thrown
    if (hasError) {
      // You can render any custom fallback UI
      return <ErrorPage statusCode={500} />;
    }

    // Return children components in case of no error
    return children;
  }
}

export default ErrorBoundary;
