import { useState, useEffect } from 'react';
import handleSubmittingCombinedForm from 'lib/handleSubmittingCombinedForm';
import useApplicant from './useApplicant';
import useCv from './useCv';
import useApplication from './useApplication';

export default function useCombinedForm({
  user,
  inputs,
  vacancy,
  latLong,
  createCvType,
  resetForm,
  callback,
}) {
  const applicant = user?.applicant;
  const applicantCv = applicant?.cvs?.edges[0]?.node;
  const [saving, setSaving] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [applicantId, setApplicantId] = useState(applicant?.id);
  const [cvId, setCvId] = useState(applicantCv?.id);

  const { createApplicant, updateApplicant, saveApplicantError } = useApplicant(
    { applicantId }
  );
  const { createCv, updateCv, uploadMediaObject, saveCvError, uploadCvError } =
    useCv({ cvId });
  const { createApplication, createApplicationError } = useApplication();

  const submitErrors = [
    saveApplicantError,
    saveCvError,
    createApplicationError,
  ].filter(error => error);

  // Set the applicant id and cv id when received
  useEffect(() => {
    if (!userLoggedIn && applicant?.id) setUserLoggedIn(true);
    if (!applicantId && applicant?.id) setApplicantId(applicant?.id);
    if (!cvId && applicantCv?.id) setCvId(applicantCv?.id);
  }, [user]);

  // This function handles the entire logic behind the submitting of all forms
  const submitCombinedForm = async () => {
    setSaving(true);
    // Call the submit handler
    await handleSubmittingCombinedForm({
      inputs,
      vacancy,
      latLong,
      applicantId,
      setApplicantId,
      createApplicant,
      updateApplicant,
      cvId,
      setCvId,
      createCv,
      updateCv,
      uploadMediaObject,
      createCvType,
      resetForm,
      createApplication,
      retrieveApplicantInfo: userLoggedIn,
    })
      .then(() => {
        if (callback) return callback();
      })
      .catch(() => setSaving(false));
  };

  return {
    submitCombinedForm,
    savingCombinedForm: saving,
    submitErrors,
    uploadCvError,
  };
}
