import { useState } from 'react';
import useVacancyAlert from './useVacancyAlert';

export default function useCreateEmailAlert({
  inputs,
  latLong,
  keywords,
  resetForm,
}) {
  const [saving, setSaving] = useState(false);
  const { createVacancyAlert, createVacancyAlertError } = useVacancyAlert();

  const createNewEmailAlert = async () => {
    setSaving(true);
    await createVacancyAlert({
      variables: {
        rangeInKm: inputs.rangeInKm,
        city: inputs.city,
        latitude: latLong?.[0],
        longitude: latLong?.[1],
        keywords: keywords.join(' '),
      },
    }).catch(() => setSaving(false));
    resetForm();
    setSaving(false);
  };

  return {
    createNewEmailAlert,
    creatingNewEmailAlert: saving,
    submitErrors: [createVacancyAlertError],
  };
}
