export const TYPE_FILE_UPLOAD = 'file-upload';
export const TYPE_COPY_PASTE = 'copy-paste';

const handleSubmittingCombinedForm = async ({
  createApplicant,
  inputs,
  latLong,
  createCv,
  createCvType,
  uploadMediaObject,
  createApplication,
  vacancy,
  retrieveApplicantInfo,
}) =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const latitude = latLong?.[0];
        const longitude = latLong?.[1];
        const {
          name,
          email,
          phoneNumber,
          city,
          cvContent,
          description,
          desiredPosition,
          cvUpload,
          motivation,
          acceptedPrivacyTerms,
        } = inputs;
        const createApplicantResponse = await createApplicant({
          variables: {
            name,
            email,
            phoneNumber,
            city,
            latitude,
            longitude,
          },
        }).catch(reject);
        // Save the response in a variable instead of reading it directly from the state,
        // This is needed because the component is not updated in between the duration of this function
        const currentApplicantId =
          createApplicantResponse?.data?.createApplicant?.applicant?.id;

        // Return if this step failed
        if (!currentApplicantId) return reject();

        const createCvResponse = await createCv({
          variables: {
            applicant: currentApplicantId,
            content: cvContent,
            description,
            desiredPosition,
          },
        }).catch(reject);
        const currentCvId = createCvResponse?.data?.createCv?.cv?.id;

        // Return if this step failed
        if (!currentCvId) return reject();

        // If the user wants to upload their cv, perform the upload action
        if (
          uploadMediaObject &&
          createCvType === TYPE_FILE_UPLOAD &&
          currentCvId &&
          cvUpload
        ) {
          const uploadMediaResponse = await uploadMediaObject({
            variables: {
              file: cvUpload,
              targetIri: currentCvId,
              targetAction: 'uploadedFile',
            },
          }).catch(reject);
          // Just to double check if the upload didn't fail on us
          const mediaObjectId =
            uploadMediaResponse?.data?.uploadMediaObject?.mediaObject?.id;
          // Return if this step failed
          if (!mediaObjectId) return reject();
        }

        if (createApplication && vacancy?.id) {
          // Third we create the application
          const { data: createApplicationData } = await createApplication({
            variables: {
              vacancy: vacancy.id,
              applicant: currentApplicantId,
              cv: currentCvId,
              motivation,
              acceptedPrivacyTerms,
              createVacancyAlert: inputs.createEmailAlert,
              retrieveApplicantInfo,
            },
          }).catch(reject);

          const applicationId =
            createApplicationData?.createApplication?.application?.id;

          if (!applicationId) return reject();
        }

        // Everything worked as expected!
        resolve();
      } catch {
        reject();
      }
    })();
  });

export default handleSubmittingCombinedForm;
