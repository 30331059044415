import PropTypes from 'prop-types';
import { format } from 'date-fns';

const DisplayDate = ({
  Tag,
  extraClasses,
  date,
  dateFormat,
  prefix,
  suffix,
  joiner,
}) => {
  const dateObj = typeof date === 'object' ? date : new Date(date);
  return (
    <Tag className={`c-display-date ${extraClasses || ''}`.trim()}>
      {[prefix, format(dateObj, dateFormat), suffix]
        .filter(e => !!e)
        .join(joiner)}
    </Tag>
  );
};

DisplayDate.propTypes = {
  Tag: PropTypes.string,
  extraClasses: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  dateFormat: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  joiner: PropTypes.string,
};

DisplayDate.defaultProps = {
  Tag: 'span',
  dateFormat: 'dd-MM-yyyy',
  joiner: ' ',
};

export default DisplayDate;
