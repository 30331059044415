import { CREATE_APPLICATION } from 'lib/mutations';
import { useMutation } from '@apollo/client';

export default function useApplication() {
  // Create application mutation
  const [createApplication, { error: createApplicationError }] =
    useMutation(CREATE_APPLICATION);

  return {
    createApplication,
    createApplicationError,
  };
}
