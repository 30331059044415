import { useMemo } from 'react';
import fetch from 'isomorphic-unfetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { setCookie } from 'utils';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import logout from 'lib/auth/logout';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

// Keep this for reference
// const mergeData = (existing, incoming) =>
//   merge(existing, incoming, {
//     // combine arrays using object equality (like in sets)
//     arrayMerge: (destinationArray, sourceArray) => [
//       ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
//       ...sourceArray,
//     ],
//   });

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  const logoutAndRedirect = () =>
    logout()
      .catch(error => console.log(error))
      .finally(() => {
        setCookie('callback-path', '/?error=AccessDenied', 1);
        // to support logging out from all windows
        window.localStorage.setItem('logout', Date.now());
        window.location.assign(`${process.env.OAUTH_ENDPOINT}/logout`);
      });

  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === 'invalid-jwt') {
        logoutAndRedirect();
      }
    });
  }
  if (networkError && networkError.statusCode === 401) logoutAndRedirect();
});

const httpLink = createUploadLink({
  uri: process.env.FRONTEND_GRAPHQL_ENDPOINT, // Server URL (must be absolute)
  credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  fetch,
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: logoutLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {},
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export async function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = await client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
