import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import Meta from 'components/Meta';
import Header from 'components/Header';
import Footer from 'components/Footer';
import IEWarning from 'components/IEWarning';
import FlashMessage from 'components/FlashMessage';

const Layout = ({
  title,
  metaDescription,
  metaKeywords,
  metaRobots,
  canonical,
  noHeader,
  children,
  noFooter,
}) => {
  const { t } = useTranslation();
  const { query } = useRouter();

  return (
    <div className="c-layout">
      <Meta
        title={title}
        metaDescription={metaDescription}
        metaKeywords={metaKeywords}
        metaRobots={metaRobots}
        canonical={canonical}
      />
      {!noHeader && <Header />}
      {query?.error && (
        <FlashMessage
          extraClasses="c-card--layout-flashmessage"
          message={t(`error.${query.error}`)}
          type="error"
        />
      )}
      {query?.success && (
        <FlashMessage
          test="successFlashMessage"
          extraClasses="c-card--layout-flashmessage"
          message={t(`success.${query.success}`)}
          type="success"
        />
      )}
      <main className="c-main">{children}</main>
      <Footer showItems={!noFooter} />
      <IEWarning />
    </div>
  );
};

export default Layout;

Layout.propTypes = {
  title: PropTypes.string,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.string,
  metaRobots: PropTypes.string,
  canonical: PropTypes.string,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Layout.defaultProps = {
  noHeader: false,
  noFooter: false,
};
