import { gql } from '@apollo/client';

export const EDUCATION_LEVELS = gql`
  query EDUCATION_LEVELS {
    educationLevels {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
