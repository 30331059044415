import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CURRENT_USER_QUERY } from 'lib/queries';
import useAuth from './useAuth';
import useVacancyAlert from './useVacancyAlert';
import useCv from './useCv';
import useApplicant from './useApplicant';

export default function usePrivacyForm({ inputs, resetForm }) {
  const { user, loading: loadingUser, logout } = useAuth();
  const [userQuery] = useLazyQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [saving, setSaving] = useState(false);
  const [applicantId, setApplicantId] = useState();
  const [cvId, setCvId] = useState();
  const { deleteAllVacancyAlerts, deleteAllVacancyAlertsError } =
    useVacancyAlert({ applicantId });
  const { deleteCv, deleteCVError } = useCv({ applicantId });
  const { deleteApplicant, deleteApplicantError } = useApplicant({
    applicantId,
  });

  // Set the applicant id and cv id when received
  useEffect(() => {
    const userApplicant = user?.applicant;
    const userApplicantId = userApplicant?.id;
    const userApplicantCv = userApplicant?.cvs?.edges[0]?.node;
    if (loadingUser || !userApplicant) return;
    if (!applicantId && userApplicantId) setApplicantId(userApplicantId);
    if (!cvId && userApplicantCv) setCvId(userApplicantCv);
  }, [user]);

  const submitErrors = [
    deleteAllVacancyAlertsError,
    deleteCVError,
    deleteApplicantError,
  ].filter(error => error);

  const submitPrivacyForm = async () => {
    setSaving(true);

    if (inputs.deleteAllEmailAlerts)
      await deleteAllVacancyAlerts().catch(() => setSaving(false));
    if (inputs.deleteCv) await deleteCv().catch(() => setSaving(false));
    if (inputs.deleteAccount) {
      await deleteApplicant()
        .then(() => logout())
        .catch(() => setSaving(false));
      return;
    }

    await userQuery({
      variables: {
        id: user?.id,
      },
    });

    resetForm();
    setSaving(false);
  };

  return {
    submitPrivacyForm,
    savingPrivacyForm: saving,
    submitErrors,
  };
}
