import { gql } from '@apollo/client';

export const CREATE_APPLICANT = gql`
  mutation CREATE_APPLICANT(
    $name: String!
    $email: String!
    $phoneNumber: String
    $city: String
    $latitude: Float
    $longitude: Float
    $vacancy: String
  ) {
    createApplicant(
      input: {
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        city: $city
        latitude: $latitude
        longitude: $longitude
        vacancy: $vacancy
      }
    ) {
      applicant {
        id
        name
        phoneNumber
        address {
          city
          latitude
          longitude
        }
      }
    }
  }
`;
