import { gql } from '@apollo/client';

export const UPLOAD_MEDIA_OBJECT = gql`
  mutation UPLOAD_MEDIA_OBJECT(
    $file: Upload!
    $targetIri: String!
    $targetAction: String!
  ) {
    uploadMediaObject(
      input: { file: $file, targetIri: $targetIri, targetAction: $targetAction }
    ) {
      mediaObject {
        id
        contentUrl
        filePath
        image
      }
    }
  }
`;
