import { gql } from '@apollo/client';

export const DELETE_APPLICANT = gql`
  mutation DELETE_APPLICANT($applicantId: ID!) {
    deleteApplicant(input: { id: $applicantId }) {
      applicant {
        id
      }
    }
  }
`;
