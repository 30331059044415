import { useEffect, useState } from 'react';
import {
  CREATE_APPLICANT,
  UPDATE_APPLICANT,
  DELETE_APPLICANT,
} from 'lib/mutations';
import { useMutation } from '@apollo/client';

export default function useApplicant({ applicantId = '' } = {}) {
  // Create applicant mutation
  const [createApplicant, { error: createApplicantError }] =
    useMutation(CREATE_APPLICANT);
  // Update applicant mutation
  const [updateApplicant, { error: updateApplicantError }] =
    useMutation(UPDATE_APPLICANT);
  // Delete cv mutation
  const [deleteApplicant, { error: deleteApplicantError }] = useMutation(
    DELETE_APPLICANT,
    {
      variables: {
        applicantId,
      },
    }
  );
  const [saveApplicant, setSaveApplicant] = useState(() => createApplicant);

  useEffect(() => {
    // This function runs when the things we are watching change
    if (applicantId) return setSaveApplicant(() => updateApplicant);
  }, [applicantId]);

  return {
    saveApplicant,
    createApplicant,
    updateApplicant,
    saveApplicantError: createApplicantError || updateApplicantError,
    deleteApplicant,
    deleteApplicantError,
  };
}
