import axios from 'axios';
import { createContext, useEffect, useState, useMemo, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CURRENT_USER_QUERY } from 'lib/queries';
import { setGraphqlUserId } from 'lib/entityIds';
import logoutCall from 'lib/auth/logout';
import { setCookie } from './clientCookies';

const AuthContext = createContext({});

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ user: initUser, children }) {
  const [user, setUser] = useState(initUser);
  const [loading, setloading] = useState(!initUser || null);
  const [error, setError] = useState();
  // Query to get the user information
  const [userQuery] = useLazyQuery(CURRENT_USER_QUERY);

  // Check if the user is logged in and retrieve the users information
  useEffect(() => {
    if (user) return;
    axios(`${process.env.FRONTEND_ENDPOINT}/api/auth/isLoggedIn`)
      .then(async res => {
        // Return if the response was not status 200
        if (res.status !== 200) {
          throw new Error('Something went wrong, please try again');
        }
        // Return if we didn't receive a userId
        if (!res.data?.userId) {
          return;
        }
        // Fetch the user information
        const { data: userData } = await userQuery({
          variables: { id: setGraphqlUserId(res.data.userId) },
        });
        // Return if we didn't receive user data,
        // When receiving an error this function already returns
        if (!userData) {
          throw new Error('User information could not, please try again');
        }
        // Finally set the user data
        setUser(userData.user);
      })
      .catch(_error => setError(_error))
      .finally(() => setloading(false));
  }, [user]);

  // Set up synced logout when using multiple browser tabs
  useEffect(() => {
    // This function makes sure the user data in the state gets erased
    const handleLogoutUser = event => {
      if (event.key === 'logout') {
        setUser(undefined);
      }
    };
    window.addEventListener('storage', handleLogoutUser);
  }, []);

  const login = ({ callbackPath } = {}) => {
    // Set the callback path to navigate the user back to the same page
    setCookie('callback-path', callbackPath || window.location.pathname, 1);
    // Redirect the user to the auth server loggin page
    window.location.assign(
      `${process.env.OAUTH_ENDPOINT}/authorize?client_id=${process.env.CLIENT_ID}&scope=GRAPHQL&response_type=code&redirect_uri=${process.env.FRONTEND_ENDPOINT}/api/auth/authorize`
    );
  };

  // Call the logout endpoint and then remove the user from the state.
  const logout = () => {
    logoutCall()
      .catch(_error => setError(_error))
      .finally(() => {
        setUser(undefined);
        // to support logging out from all windows
        window.localStorage.setItem('logout', Date.now());
        window.location.assign(`${process.env.OAUTH_ENDPOINT}/logout`);
      });
  };

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({ user, setUser, loading, error, login, logout }),
    [user, setUser, loading, error]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
