import { gql } from '@apollo/client';

export const basicJobPostingFragment = gql`
  fragment basicJobPostingFields on Vacancy {
    id
    title
    slug
    description
    vacancyStats {
      views
    }
    employmentType {
      hours: name
    }
    companyBranch {
      company: name
    }
    address {
      location: city
    }
  }
`;
