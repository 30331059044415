import { gql } from '@apollo/client';
import { cvFragment } from 'lib/fragments';

export const CREATE_CV = gql`
  mutation CREATE_CV(
    $applicant: String!
    $content: String
    $description: String
    $desiredPosition: String
  ) {
    createCv(
      input: {
        applicant: $applicant
        content: $content
        description: $description
        desiredPosition: $desiredPosition
      }
    ) {
      cv {
        ...cvFields
      }
    }
  }
  ${cvFragment}
`;
