import { gql } from '@apollo/client';
import { basicJobPostingFragment } from 'lib/fragments';

export const EMPLOYER_VACANCIES = gql`
  query EMPLOYER_VACANCIES(
    $id: ID!
    $first: Int
    $before: String
    $after: String
  ) {
    companyBranch(id: $id) {
      vacancies(first: $first, before: $before, after: $after) {
        edges {
          node {
            ...basicJobPostingFields
          }
        }
        totalCount
        pageInfo {
          endCursor
          startCursor
        }
      }
    }
  }
  ${basicJobPostingFragment}
`;
