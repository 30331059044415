import { gql } from '@apollo/client';

export const UPDATE_APPLICANT = gql`
  mutation UPDATE_APPLICANT(
    $id: ID!
    $email: String
    $name: String
    $phoneNumber: String
    $city: String
    $latitude: Float
    $longitude: Float
    $plainOldPassword: String
    $plainNewPassword: String
  ) {
    updateApplicant(
      input: {
        id: $id
        email: $email
        name: $name
        phoneNumber: $phoneNumber
        city: $city
        latitude: $latitude
        longitude: $longitude
        plainOldPassword: $plainOldPassword
        plainNewPassword: $plainNewPassword
      }
    ) {
      applicant {
        id
        name
        phoneNumber
        address {
          city
          latitude
          longitude
        }
      }
    }
  }
`;
