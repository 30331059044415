import Layout from 'components/Layout';
import Card from 'components/Card';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

const ErrorPage = ({ statusCode, title, description, error }) => {
  const { t } = useTranslation();
  const isDev = process.env.ENV === 'dev';
  if (isDev && error) console.error(error);
  return (
    <Layout
      title={title || t('error.error_heading', { context: `${statusCode}` })}
    >
      <div className="o-retain o-retain--wall">
        <div className="u-text-retain u-margin-top-huge u-margin-bottom-huge">
          <h1>
            {title || t('error.error_heading', { context: `${statusCode}` })}
          </h1>
          <p>
            {description ||
              t('error.error_description', { context: `${statusCode}` })}
          </p>
        </div>

        {isDev && error && (
          <Card extraClasses="u-margin-bottom-huge">
            <code>{JSON.stringify(error)}</code>
          </Card>
        )}
      </div>
    </Layout>
  );
};

ErrorPage.propTypes = {
  statusCode: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.any,
};

export default ErrorPage;
