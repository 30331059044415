import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'next-i18next';
import Button from 'components/Button';
// icons
import Person from 'svg/icon-person.svg';
import Bell from 'svg/icon-bell.svg';
import Briefcase from 'svg/icon-briefcase.svg';
import Shield from 'svg/shield.svg';
import File from 'svg/icon-edit-file.svg';
import IconLogout from 'svg/icon-logout.svg';
import { useAuth } from 'utils';

const DashboardLinks = ({ showLogoutButton, extraClasses }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const links = [
    {
      label: 'profile',
      href: '',
      icon: Person,
    },
    {
      label: 'cv',
      href: 'cv',
      icon: File,
    },
    {
      label: 'applications',
      href: 'sollicitaties',
      icon: Briefcase,
    },
    {
      label: 'alerts',
      href: 'vacature-alerts',
      icon: Bell,
    },
    {
      label: 'privacy',
      href: 'privacy',
      icon: Shield,
    },
  ];

  return (
    <ul
      className={`c-dashboard-links o-list-clean u-margin-none ${
        extraClasses || ''
      }`}
    >
      {links.map(({ label, href, icon }) => (
        <li className="c-dashboard-link__list-item" key={href}>
          <Button
            test={t(`dashboard.${label}.navTitle`)}
            Tag="a"
            label={t(`dashboard.${label}.navTitle`)}
            href={['/profiel', href].filter(p => !!p).join('/')}
            IconLeft={icon}
            extraClasses="c-button--full-width c-button--link c-dashboard-link__link"
          />
        </li>
      ))}
      {showLogoutButton && (
        <li className="c-dashboard-link__list-item">
          <Button
            label={t(`header.logout`)}
            IconLeft={IconLogout}
            onClick={logout}
            test="header-logout-button"
            extraClasses="c-button--full-width c-button--link c-dashboard-link__link"
          />
        </li>
      )}
    </ul>
  );
};

DashboardLinks.defaultProps = {
  showLogoutButton: false,
};

DashboardLinks.propTypes = {
  showLogoutButton: PropTypes.bool,
  extraClasses: PropTypes.string,
};

export default DashboardLinks;
