import { gql } from '@apollo/client';
import { basicJobPostingFragment } from 'lib/fragments';

export const EMPLOYER = gql`
  query EMPLOYER($slug: String!, $first: Int) {
    companyBranches(slug: $slug) {
      edges {
        node {
          id
          detailBackgroundImage {
            contentUrl
            image
            public
          }
          metaTitle
          metaDescription
          foundedAt
          kvkName
          kvkNumber
          company {
            id
            name
            branches {
              totalCount
            }
          }
          logo {
            contentUrl
            image
          }
          numberOfEmployees
          name
          phoneNumber
          address {
            street
            houseNumber
            houseNumberSuffix
            zipcode
            city
            state
            country
            latitude
            longitude
          }
          category {
            name
          }
          description
          employerMotivation
          vacancies(first: $first) {
            edges {
              node {
                ...basicJobPostingFields
              }
            }
            totalCount
            pageInfo {
              endCursor
              startCursor
            }
          }
          youtubeId
        }
      }
    }
  }
  ${basicJobPostingFragment}
`;
