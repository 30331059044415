import { gql } from '@apollo/client';

export const CREATE_APPLICATION = gql`
  mutation CREATE_APPLICATION(
    $vacancy: String!
    $cv: String!
    $motivation: String
    $acceptedPrivacyTerms: Boolean!
    $retrieveApplicantInfo: Boolean!
    $createVacancyAlert: Boolean
    $applicant: String!
  ) {
    createApplication(
      input: {
        vacancy: $vacancy
        cv: $cv
        motivation: $motivation
        createVacancyAlert: $createVacancyAlert
        acceptedPrivacyTerms: $acceptedPrivacyTerms
        applicant: $applicant
      }
    ) {
      application {
        id
        applicant @include(if: $retrieveApplicantInfo) {
          id
          vacancyAlerts(order: { createdAt: "DESC" }) {
            edges {
              node {
                id
                createdAt
                keywords
                latitude
                longitude
                city
                rangeInKm
                category {
                  id
                  name
                }
                educationLevel {
                  id
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    }
  }
`;
