import { gql } from '@apollo/client';
import {
  basicJobPostingFragment,
  extendedJobPostingFragment,
} from 'lib/fragments';

export const SINGLE_JOB_POSTING = gql`
  query SINGLE_JOB_POSTING($id: ID!) {
    vacancy(id: $id) {
      ...basicJobPostingFields
      ...extendedJobPostingFields
    }
  }
  ${basicJobPostingFragment}
  ${extendedJobPostingFragment}
`;
