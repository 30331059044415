import { gql } from '@apollo/client';

export const GET_VACANCY_BY_REMOTE_ID = gql`
  query GET_VACANCY_BY_REMOTE_ID($remoteId: Int!) {
    byRemoteIdVacancy(remoteId: $remoteId) {
      id
      slug
      address {
        city
      }
    }
  }
`;
