import { useTranslation } from 'next-i18next';
import Card from 'components/Card';

const IEWarning = () => {
  const { t } = useTranslation();

  return (
    <section className="c-ie-warning">
      <Card extraClasses="c-ie-warning__modal">
        <p>{t('ie.heading')}</p>
        <p>{t('ie.description')}</p>
        <ul className="c-ie-warning__list">
          {['chrome', 'firefox', 'edge'].map(browser => (
            <li key={browser}>
              <a
                href={t(`ie.${browser}.href`)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`ie.${browser}.label`)}
              </a>
            </li>
          ))}
        </ul>
      </Card>
    </section>
  );
};

export default IEWarning;
