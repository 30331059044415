import PropTypes from 'prop-types';
import LogoIcon from 'svg/logo-icon.svg';
import FooterTop from 'components/Footer/footer-top';
import FooterBottom from 'components/Footer/footer-bottom';

const Footer = ({ extraClasses, showItems }) => (
  <footer className={`c-footer ${extraClasses || ''}`.trim()}>
    <LogoIcon className="c-footer__backdrop" />
    <div className="c-footer__content o-retain o-retain--wall">
      {!!showItems && <FooterTop />}
      <FooterBottom />
    </div>
  </footer>
);

export default Footer;

Footer.propTypes = {
  extraClasses: PropTypes.string,
  showItems: PropTypes.bool,
};

Footer.defaultProps = {
  showItems: true,
};
