import { gql } from '@apollo/client';

export const EMPLOYER_WEBSITE = gql`
  query EMPLOYER_WEBSITE($id: ID!) {
    companyBranch(id: $id) {
      company {
        websiteActiveTo
        website {
          slug
        }
      }
    }
  }
`;
