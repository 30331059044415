import { gql } from '@apollo/client';

export const EXTRA_VACANCIES_DATA = gql`
  query EXTRA_VACANCIES_DATA($ids: [String]) {
    vacancies(id_list: $ids) {
      edges {
        node {
          id
          slug
          vacancyStats {
            views
          }
        }
      }
    }
  }
`;
