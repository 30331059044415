import { EMPLOYER_WEBSITE } from 'lib/queries';
import { useQuery } from '@apollo/client';
import { isFuture } from 'date-fns';

export default function useCompanyWebsite({ id = '' } = {}) {
  const { data } = useQuery(EMPLOYER_WEBSITE, {
    variables: {
      id,
    },
  });

  const websiteSlug = data?.companyBranch.company?.website?.slug;
  const websiteActiveTo = data?.companyBranch.company?.websiteActiveTo;
  const websiteIsActive =
    websiteSlug && websiteActiveTo && isFuture(new Date(websiteActiveTo));
  return {
    websiteSlug,
    websiteIsActive,
  };
}
