import { gql } from '@apollo/client';

export const extendedJobPostingFragment = gql`
  fragment extendedJobPostingFields on Vacancy {
    youtubeId
    metaTitle
    metaDescription
    educationLevel {
      name
    }
    publishFrom
    publishedAt
    publishTo
    logo {
      id
      filePath
      contentUrl
      image
    }
    companyBranch {
      logo {
        id
        filePath
        contentUrl
        image
      }
      slug
      id
      defaultMotivationalText
    }
    backgroundImage {
      image
      public
      contentUrl
    }
    category {
      name
    }
    address {
      street
      houseNumber
      houseNumberSuffix
      zipcode
      city
      state
      country
      latitude
      longitude
    }
    salaryMin
    salaryMax
    applyUrl
    screeningQuestions {
      edges {
        node {
          id
          question
          required
        }
      }
    }
    usps {
      edges {
        node {
          id
          icon
          text
        }
      }
    }
  }
`;
