import { gql } from '@apollo/client';

export const CREATE_VACANCY_ALERT = gql`
  mutation CREATE_VACANCY_ALERT(
    $city: String
    $latitude: Float
    $longitude: Float
    $rangeInKm: Int
    $keywords: String
  ) {
    createVacancyAlert(
      input: {
        keywords: $keywords
        city: $city
        latitude: $latitude
        longitude: $longitude
        rangeInKm: $rangeInKm
      }
    ) {
      vacancyAlert {
        id
        applicant {
          vacancyAlerts(order: { createdAt: "DESC" }) {
            edges {
              node {
                id
                createdAt
                keywords
                latitude
                longitude
                city
                rangeInKm
                category {
                  id
                  name
                }
                educationLevel {
                  id
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    }
  }
`;
