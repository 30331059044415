import { gql } from '@apollo/client';

export const cvFragment = gql`
  fragment cvFields on Cv {
    id
    content
    uploadedFile {
      id
      contentUrl
      filePath
    }
    uploadedFileText
    description
    desiredPosition
  }
`;
