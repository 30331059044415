import { gql } from '@apollo/client';

export const DELETE_VACANCY_ALERT = gql`
  mutation DELETE_VACANCY_ALERT($vacancyAlertId: ID!) {
    deleteVacancyAlert(input: { id: $vacancyAlertId }) {
      vacancyAlert {
        id
      }
    }
  }
`;
