import { Component } from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import PropTypes from 'prop-types';

class NextNProgress extends Component {
  timer = null;

  componentDidMount() {
    const { options } = this.props;

    if (options) {
      NProgress.configure(options);
    }

    Router.events.on('routeChangeStart', this.routeChangeStart);
    Router.events.on('routeChangeComplete', this.routeChangeEnd);
    Router.events.on('routeChangeError', this.routeChangeEnd);
  }

  routeChangeStart = () => {
    const { startPosition } = this.props;

    NProgress.set(startPosition);
    NProgress.start();
  };

  routeChangeEnd = () => {
    const { stopDelayMs } = this.props;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      NProgress.done(true);
    }, stopDelayMs);
  };

  render() {
    return null;
  }
}

NextNProgress.defaultProps = {
  startPosition: 0.3,
  stopDelayMs: 150,
};

NextNProgress.propTypes = {
  startPosition: PropTypes.number,
  stopDelayMs: PropTypes.number,
  options: PropTypes.object,
};

export default NextNProgress;
