import { gql } from '@apollo/client';

export const DELETE_ALL_CVS_APPLICANT = gql`
  mutation DELETE_ALL_CVS_APPLICANT($applicantId: ID!) {
    deleteAllCvsApplicant(input: { id: $applicantId }) {
      applicant {
        id
      }
    }
  }
`;
