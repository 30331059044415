import { gql } from '@apollo/client';

export const DELETE_ALL_VACANCY_ALERTS = gql`
  mutation DELETE_ALL_VACANCY_ALERTS($applicantId: ID!) {
    deleteAllVacancyAlertsApplicant(input: { id: $applicantId }) {
      applicant {
        id
      }
    }
  }
`;
