const getHighlightedText = (text, query) => {
  const trimmedQuery = query.replace(/[^\w\s]/gi, '').trim();

  if (trimmedQuery.length <= 0) return text;

  const words = trimmedQuery.split(' ');
  const regex = new RegExp(
    `(?![^<]+>)(${words.join(' ')})|(${words.join('|')})`,
    'ig'
  );
  const highlightedText = text.replace(
    regex,
    `<span style="font-weight: 800;">$&</span>`
  );
  return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

export default getHighlightedText;
