import React from 'react';

const Favicons = () => (
  <>
    <link rel="shortcut icon" href="/icons/favicon.ico" />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/icons/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/icons/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/icons/favicon-16x16.png"
    />
    <link rel="manifest" href="/icons/site.webmanifest" />
    <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#004fb7" />
    <meta name="msapplication-TileColor" content="#004fb7" />
    <meta name="theme-color" content="#ffffff" />
  </>
);

export default Favicons;
