import Head from 'next/head';
import PropTypes from 'prop-types';

const Meta = ({
  title,
  metaDescription,
  metaKeywords,
  metaRobots,
  canonical,
  suffix,
}) => (
  <Head>
    <title>{[title, suffix].filter(part => !!part).join(' - ')}</title>
    {!!metaDescription && <meta name="description" content={metaDescription} />}
    {!!metaKeywords && <meta name="keywords" content={metaKeywords} />}
    {!!metaRobots && <meta name="robots" content={metaRobots} />}
    {!!canonical && <link rel="canonical" href={canonical} />}
  </Head>
);

Meta.propTypes = {
  title: PropTypes.string,
  suffix: PropTypes.string,
  metaDescription: PropTypes.string,
  canonical: PropTypes.string,
  metaKeywords: PropTypes.string,
  metaRobots: PropTypes.string,
};

Meta.defaultProps = {
  suffix: 'deBanenSite.nl',
};

export default Meta;
