import { gql } from '@apollo/client';
import { cvFragment } from 'lib/fragments';

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY($id: ID!) {
    user(id: $id) {
      id
      email
      applicant {
        id
        name
        phoneNumber
        address {
          city
          latitude
          longitude
        }
        cvs(first: 1) {
          edges {
            node {
              ...cvFields
            }
          }
        }
        applications(order: { createdAt: "DESC" }) {
          edges {
            node {
              id
              createdAt
              vacancy {
                id
                slug
                title
                companyBranch {
                  name
                }
                address {
                  city
                }
                employmentType {
                  name
                }
                publishTo
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
        vacancyAlerts(order: { createdAt: "DESC" }) {
          edges {
            node {
              id
              createdAt
              keywords
              latitude
              longitude
              city
              rangeInKm
              category {
                id
                name
              }
              educationLevel {
                id
                name
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  }
  ${cvFragment}
`;
