import { gql } from '@apollo/client';

export const VACANCY_ALERT = gql`
  query VACANCY_ALERT($vacancyAlertId: ID!) {
    vacancyAlert(id: $vacancyAlertId) {
      id
      keywords
    }
  }
`;
