import { gql } from '@apollo/client';

export const EXTRA_EMPLOYER_DATA = gql`
  query EXTRA_EMPLOYER_DATA($slugs: [String]) {
    companyBranches(slug_list: $slugs) {
      edges {
        node {
          id
          slug
          logo {
            contentUrl
            image
          }
          onlineVacancies: vacancies(online: true) {
            totalCount
          }
        }
      }
    }
  }
`;
