export const catchInternalUrl = url => {
  if (!url || typeof url !== 'string') return url;

  const INTERNAL_DOMAINS_REGEXP = process.env.ENVIRONMENT_URL;
  const ABSOLUTE_PATH_REGEXP = /(http|https|http:|https:|www\.|\.nl|\.com)/gi;

  // If url is relative path -> return url
  if (!url.match(ABSOLUTE_PATH_REGEXP)) return url;

  // If url is absolute but contains internal domains -> take path from url and return
  if (url.match(INTERNAL_DOMAINS_REGEXP))
    return url.split(process.env.ENVIRONMENT_URL)[2];

  // If url is external -> return null
  return null;
};
