import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useAuth } from 'utils';
import Button from 'components/Button';
import DashboardLinks from 'components/DashboardLinks';
import Logo from 'svg/logo.svg';
import Person from 'svg/icon-person.svg';
import ArrowDown from 'svg/arrow-down.svg';
import MenuToggle from 'svg/menu-toggle.svg';
import Cross from 'svg/cross.svg';
import Link from 'next/link';
import { employerLoginEndpoint } from '../config';

const HeaderLink = ({
  linkType,
  extraClasses,
  onClick,
  IconLeft,
  href,
  test,
  rel,
}) => {
  const { t } = useTranslation();
  const label = t(`header.${linkType}.label`);
  const Tag = href ? 'a' : 'button';

  return (
    <Button
      Tag={Tag}
      href={href}
      IconLeft={IconLeft}
      extraClasses={`c-button--transparent c-header__link ${
        extraClasses || ''
      }`.trim()}
      label={label}
      onClick={onClick}
      rel={rel}
      test={test}
    />
  );
};

HeaderLink.propTypes = {
  linkType: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  test: PropTypes.string,
  extraClasses: PropTypes.string,
  IconLeft: PropTypes.func,
};

const Header = ({ extraClasses }) => {
  const { t } = useTranslation();
  const [menuToggleOpen, setMenuToggleOpen] = useState(false);
  const { loading: loadingUser, user, login } = useAuth();

  return (
    <header className={`c-header ${extraClasses || ''}`.trim()}>
      <div className="o-retain o-retain--wall c-header__wrapper">
        {/* Toggle */}
        <Button
          extraClasses={`c-button--full-width c-button--transparent c-header__menu-toggle c-header__menu-toggle--${
            menuToggleOpen ? 'close' : 'open'
          }`}
          label={t(`header.menuToggle.${menuToggleOpen ? 'close' : 'open'}`)}
          onClick={() => {
            setMenuToggleOpen(!menuToggleOpen);
          }}
          hideLabel
          Icon={menuToggleOpen ? Cross : MenuToggle}
        />

        {/* Logo */}
        <Link href="/">
          <a className="c-header__logo-link">
            <span className="u-visually-hidden">
              {t('header.logoLink.label')}
            </span>

            <Logo aria-hidden />
          </a>
        </Link>

        {/* Menu wrapper */}
        <div
          className={`c-header__nav-container ${
            menuToggleOpen ? 'c-header__nav-container--open' : ''
          }`.trim()}
        >
          {/* Main nav */}
          <nav className="c-header__main-nav">
            <ul className="o-layout o-layout--gutter-base c-header__main-nav-items">
              {['overview', 'employers', 'upload'].map(link => (
                <li
                  className="o-layout__cell o-layout__cell--fit@from-lg"
                  key={link}
                >
                  <HeaderLink
                    linkType={link}
                    key={link}
                    href={t(`header.${link}.href`)}
                  />
                </li>
              ))}
            </ul>
          </nav>
          {/* Secondary nav */}
          <nav className="c-header__secondary-nav">
            <HeaderLink
              linkType="addJobPosting"
              href={t('header.addJobPosting.href')}
              extraClasses="c-button--tiny c-button--link c-header__link--secondary"
              key="addJobPosting"
            />
          </nav>
          {/* Login nav */}
          <nav className="c-header__login-nav">
            {user ? (
              <>
                <Button
                  label={t('header.loggedInCta')}
                  IconRight={ArrowDown}
                  extraClasses="c-header__login-toggle"
                  test="user-logged-in"
                />
                <DashboardLinks
                  showLogoutButton
                  extraClasses="c-header__login-nav-items c-dashboard-links--header-menu"
                />
              </>
            ) : (
              <>
                <Button
                  label={t('header.loginCta')}
                  IconLeft={Person}
                  IconRight={ArrowDown}
                  extraClasses="c-header__login-toggle"
                  test="header-login-toggle"
                  disabled={loadingUser}
                />
                <ul className="o-list-clean c-header__login-nav-items">
                  <li className="c-header__login-item">
                    <HeaderLink
                      linkType="loginEmployee"
                      extraClasses="c-header__link--login"
                      onClick={login}
                      test="employer-login-link"
                      rel="nofollow"
                    />
                  </li>
                  <li className="c-header__login-item">
                    <HeaderLink
                      href={employerLoginEndpoint}
                      linkType="loginEmployer"
                      extraClasses="c-header__link--login"
                      rel="nofollow"
                    />
                  </li>
                </ul>
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  extraClasses: PropTypes.string,
};
