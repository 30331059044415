import { gql } from '@apollo/client';
import { basicJobPostingFragment } from 'lib/fragments';

export const HOMEPAGE_JOB_POSTINGS = gql`
  query HOMEPAGE_JOB_POSTINGS {
    highlightedJobPostings: vacancies(
      online: true
      active: true
      publishFrom: { before: "now" }
      publishTo: { after: "now" }
      order: { top: "DESC" }
      first: 5
    ) {
      edges {
        node {
          ...basicJobPostingFields
        }
      }
    }
    latestJobPostings: vacancies(
      online: true
      active: true
      publishFrom: { before: "now" }
      publishTo: { after: "now" }
      order: { createdAt: "DESC" }
      first: 5
    ) {
      edges {
        node {
          ...basicJobPostingFields
        }
      }
    }
  }
  ${basicJobPostingFragment}
`;
