import { gql } from '@apollo/client';
import { cvFragment } from 'lib/fragments';

export const UPDATE_CV = gql`
  mutation UPDATE_CV(
    $id: ID!
    $content: String
    $description: String
    $desiredPosition: String
  ) {
    updateCv(
      input: {
        id: $id
        content: $content
        description: $description
        desiredPosition: $desiredPosition
      }
    ) {
      cv {
        ...cvFields
      }
    }
  }
  ${cvFragment}
`;
