import { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { SINGLE_JOB_POSTING, HAS_APPLIED_VACANCY } from 'lib/queries';
import { setGraphqlVacancyId } from 'lib/entityIds';
import useAuth from './useAuth';

export default function useVacancy({ vacancyId }) {
  const { user, loading: loadingUser } = useAuth();
  const {
    loading: loadingVacancy,
    error: vacancyError,
    data: { vacancy } = { vacancy: null },
  } = useQuery(SINGLE_JOB_POSTING, {
    variables: { id: setGraphqlVacancyId(vacancyId) },
  });
  const [
    userHasAppliedCheck,
    { data: userHasAppliedData, error: userHasAppliedError },
  ] = useLazyQuery(HAS_APPLIED_VACANCY);
  const [userHasAlreadyApplied, setUserHasAlreadyApplied] = useState();

  // Check if the currently logged in user has already applied to this vacancy
  useEffect(() => {
    // User is loading, return null
    if (loadingUser) return setUserHasAlreadyApplied(null);
    // User is not logged in, user has not yet applied
    if (!loadingUser && !user) return setUserHasAlreadyApplied(false);
    // User is logged in, perform check if user has logged in
    if (
      !loadingUser &&
      user?.id &&
      (!userHasAppliedError || !userHasAppliedData)
    )
      setUserHasAlreadyApplied(null);
    return userHasAppliedCheck({
      variables: { id: setGraphqlVacancyId(vacancyId) },
    });
  }, [user, loadingUser]);

  useEffect(() => {
    if (userHasAppliedError) {
      setUserHasAlreadyApplied(true);
    }
  }, [userHasAppliedError]);

  useEffect(() => {
    if (userHasAppliedData) {
      setUserHasAlreadyApplied(false);
    }
  }, [userHasAppliedData]);

  // Check if the currently logged in user has already applied to this vacancy
  useEffect(() => {
    const applicantApplications = user?.applicant?.applications?.edges;
    let applicationHasCurrentVacancyId = !loadingUser ? false : null;
    if (applicantApplications?.length) {
      applicantApplications.forEach(({ node: application }) => {
        if (application.vacancy.id === setGraphqlVacancyId(vacancyId))
          applicationHasCurrentVacancyId = true;
      });
    }
    setUserHasAlreadyApplied(applicationHasCurrentVacancyId);
  }, [user, vacancyId, loadingUser]);

  return {
    loadingVacancy,
    vacancyError,
    userHasAlreadyApplied,
    vacancy,
  };
}
